* {
    box-sizing: border-box;
  }

  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .auth-inner {
    /* height: 450px;
    width: 450px; */
    /* margin: auto; */
    background: #ffffff;
    box-shadow: 1px 1px 5px 5px rgba(25, 35, 58, 0.1); 
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .5s;
  }

  .emp-container {
    overflow-x: auto;
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 1px 1px 5px 5px rgba(25, 35, 58, 0.1); 
    border-radius: 15px;
    transition: all .5s;
  }

  .status-container {
    overflow-x: none;
    margin-bottom: 20px;
    background: #ffffff;
    padding: 20px 20px 20px 20px;
    box-shadow: 1px 1px 5px 5px rgba(199, 208, 233, 0.1); 
    border-radius: 2px;
    transition: all .5s;
  }